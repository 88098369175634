import { $ } from '../helper/utils';

export default () => {
  const showMenuBtn = $('.show-menu');
  const mobileNav = $('.mobile-nav');
  const closeMobileNav = $('.close-mobile-menu');
  let navOpen = false;
  const mobileTl = new TimelineLite({
    paused: true,
    onComplete() {
      navOpen = true;
      mobileNav.setAttribute('aria-hidden', false);
    },
    onReverseComplete() {
      navOpen = false;
      mobileNav.setAttribute('aria-hidden', true);
    },
  });

  mobileTl.to(mobileNav, 0.275, {
    xPercent: 0,
    autoAlpha: 1,
    pointerEvents: 'auto',
    ease: Sine.easeOut,
    startAt: {
      xPercent: -100,
    },
  });

  // open nav on menu button lick
  showMenuBtn.addEventListener('click', () => {
    document.documentElement.classList.add('noscroll');
    requestAnimationFrame(() => mobileTl.play());
  }, { passive: true });

  // close mobile nav
  closeMobileNav.addEventListener('click', () => {
    if (navOpen === true) {
      document.documentElement.classList.remove('noscroll');
      requestAnimationFrame(() => mobileTl.reverse());
    }
  }, { passive: true });
};
