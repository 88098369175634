import { $, throttle } from '../helper/utils';

export default () => {
  const header = $('#header');
  let winHeight = window.innerHeight;

  window.addEventListener('resize', throttle(() => {
    winHeight = window.innerHeight;
  }, 150));

  let lastScroll = 0;
  let timeout = null;

  // wait a second because sometimes the event fires immediately after page load
  // which makes it look jerky
  setTimeout(() => {
    window.addEventListener('scroll', throttle(() => {
      const y = window.scrollY || document.documentElement.scrollTop;

      if (y > (winHeight / 3) && y > lastScroll) {
        header.classList.add('out');
        clearTimeout(timeout);
        timeout = null;
      } else if (y < lastScroll) {
        if (timeout === null) {
          timeout = setTimeout(() => {
            header.classList.remove('out');
            timeout = null;
          }, 300);
        }
      }

      lastScroll = y;
    }), 250);
  }, 1000);
};
