/* global Linear */

import { $, $$ } from './helper/utils';
import outline from './helper/outline';
import navigation from './components/navigation';
import header from './components/header';

outline();
navigation();
header();

export default function () {
  if ($('.home') !== null) {
    import('./pages/home.js')
      .then(home => home.default())
      .catch((err) => {
        console.warn('Chunk home.js loading failed', err);
      });
  }

  if ($('.lightbox') !== null) {
    import('./components/lightbox.js')
      .then(Lightbox => Lightbox.default('.lightbox'))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  }

  if ($('.nav-tabs') !== null || $('.accordion') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
}
